import React, { useState, useEffect } from "react";
import DashFooter from "../DashFooter/DashFooter";
import DashHeader from "../DashHeader/DashHeader";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { API } from "../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../Redux/AuthSlice";
import DOMPurify from "dompurify";

const Downline = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(50);
  const [status, setstatus] = useState(2);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [search, setSearch] = useState("");
  const [TotalAmout, setTotalAmout] = useState("");

  const [position, setPosition] = useState(0);

  const { userId, jwtToken } = useSelector((state) => state.Auth);
  console.log("12", listPerpage, currentPage)
  const Downline_API = async () => {
    try {
      let responce = await API?.post(
        `/myDownline`,
        {
          uid: userId,
          searchid: search,
          fdate: toDate,
          tdate: toDate,
          status: status,
          position: position,
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json", // Adjust the content type as needed
          },
        }
      );
      console.log("activationHistory", responce.data.data1);
      setTotalAmout(responce.data.data1);
      responce = responce?.data?.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item?.row,
          Registration_Date: item?.registrationDate,
          User_ID: item?.uid,
          Position: item?.position,
          Wallet: `${item?.wallet?.substring(0, 6)}...${item?.wallet?.substring(
            item?.wallet?.length - 4
          )}`,
          Status: item?.status,

          packagename: `$ ${item?.totalPackage}`,
          Activation_Date:
            item?.activationDate == null ? "Null" : item?.activationDate,
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            isAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };

  useEffect(() => {
    Downline_API();
  }, []);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);

  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "S.No", accessor: "sr" },
      { Header: "User ID", accessor: "User_ID" },
      { Header: "Wallet", accessor: "Wallet" },
      { Header: "Registration_Date", accessor: "Registration_Date" },
      { Header: "Position", accessor: "Position" },
      { Header: "Package", accessor: "packagename" },
      { Header: "Status", accessor: "Status" },

      { Header: "Activation Date", accessor: "Activation_Date" },
    ],
  });

  const handleUserIdChange = (e) => {
    const inputText = e.target.value.replace(/[^0-9]/gi, "").substring(0, 7);
    const sanitizeValue = DOMPurify.sanitize(inputText);
    setSearch(sanitizeValue);
  };

  const handlePositionChange = (value) => {
    setPosition(value);
  };

  return (
    <div>
      <DashHeader />
      <div className="page-wrapper">
        <div className="page-content adjustmentsection">
          <div className="row mx-0  py-3">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-18 text-white fw-bold">Downline ( {TotalAmout} )</div>
                <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                  <div className="breadcrumb-title pe-3">Downline </div>
                  <div className="ps-3">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 p-0">
                        <li className="breadcrumb-item">
                          <a href="javascript:;">
                            <i className="bx bx-home-alt text-white" />
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Downline
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-0 mb-2">
            <div className="col-md-2">
              <label className="text-white fs-14">User Id</label>
              <input
                type="text"
                name="from_date"
                id="from_date"
                className="form-control text-white shadow-none text-white bgmain"
                placeholder="User ID"
                value={search}
                onChange={handleUserIdChange}
              />
            </div>
            <br />
            <br />
            <div className="col-md-2">
              <label className="text-white fs-14">Position</label>
              <select
                className="form-control shadow-none bgmain"
                id="status"
                style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                value={position}
                onChange={(e) => handlePositionChange(e.target.value)}
              >
                <option
                  value=""
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Select Position
                </option>
                <option
                  value={0}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  All
                </option>
                <option
                  value={1}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Left
                </option>
                <option
                  value={2}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Right
                </option>
              </select>
            </div>
            <br />
            <div className="col-md-2">
              <label className="text-white fs-14">Status</label>
              <select
                className="form-control shadow-none bgmain"
                id="status"
                style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                onChange={(e) => setstatus(e.target.value)}
              >
                <option
                  value={2}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  All
                </option>
                <option
                  value={1}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Active
                </option>
                <option
                  value={0}
                  style={{ color: "rgb(255, 255, 255)", background: "#E5B80B" }}
                >
                  Inactive
                </option>
              </select>
            </div>
            <br />
            <div class="col-md-2">
              <label> From Date</label>
              <input
                type="date"
                name="from_date"
                onChange={e => setToDate(e.target.value)}
                id="from_date"
                class="form-control"
                placeholder="dd-mm-yyyy"
              />
            </div>
            <div class="col-md-2">
              <label> To Date</label>
              <input
                type="date"
                placeholder="dd-mm-yyyy"
                onChange={e => setFromDate(e.target.value)}
                name="to_date"
                id="to_date"
                class="form-control"
              />
            </div>
            <br />
            <div className="col-md-auto mt-1">
              <input
                type="submit"
                name="to_date"
                className="btn btn-primary mt_5 bgmain border-0 px-3 shadow-none w-auto"
                defaultValue="Search"
                onClick={() => Downline_API()}
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-12 jashja">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
      <DashFooter />
    </div>
  );
};

export default Downline;
