import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadWeb3 } from "../../Utils/contract";
import { API } from "../../Utils/API";
import { updateAuth } from "../../Redux/AuthSlice";

const Login = () => {
  let navigate = useNavigate();
  const [spinnerload, setspinnerload] = useState(false);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [LoginId, setLoginId] = useState("");
  const [connected, setconnected] = useState(true);
  const [ipAddress, setIP] = useState("");
  const dispatch = useDispatch();

  const walletConnected = async () => {
    try {
      let acc = await loadWeb3();
      let response = await axios.get("https://api64.ipify.org/?format=json");
      console.log("response Geolocation", response.data.ip);
      setIP(response.data.ip);
      let ipaddress = response.data.ip;

      if (acc === "No Wallet" || acc === undefined) {
        toast.error("No Wallet");
        setconnected("Wallet is locked");
      } else if (acc === "Wrong Network") {
        toast.error(" Wrong Network Please Connect BSc Network");
        console.log("Wrong Network Please Connect BSC Scan Network");
        setconnected("Wrong Network");
      } else {
        // setaddress(acc)
        setconnected("Wallet is Connected");
        // setLoginId("0X484Demo848sdcssd48");
        setLoginId(acc);

        if (acc !== "") {
          let res = await API.post(`/userlogin`, {
            password: acc,
            ipaddress: ipaddress,
          });
          // console.log("UserloginNew Auto", res);
          let UserID = res.data.data;
          if (res.data.data.result === "Successfull") {
            toast.success(`Login Successful`);
            // console.log("loginmask", res.data.token);
            dispatch(
              updateAuth({
                isAuth: true,
                userId: res.data.data.uid_output,
                jwtToken: res.data.token,
                ipAddress: ipaddress,
              })
            );
            navigate("/dashboard");
          } else {
            toast.error(res.data.data.result);
          }
        }
      }
    } catch (e) {
      console.log("Error While WalletConnect", e);
    }
  };

  const onSubmitHandler = async (data) => {
    // console.log("LoginId", LoginId);
    let acc = await loadWeb3();

    setspinnerload(true);
    let response = await axios.get("https://api64.ipify.org/?format=json");
    console.log("response Geolocation", response.data.ip);
    setIP(response.data.ip);
    let ipaddress = response.data.ip;
    let res = await API.post(`/userlogin`, {
      password: LoginId,
      ipaddress: ipaddress,
    });
    let UserID = res.data.data;
    if (res.data.data.result === "Successfull") {
      toast.success(res.data.data.result);
      // console.log("login" , res );
      dispatch(
        updateAuth({
          isAuth: true,
          userId: res.data.data.uid_output,
          jwtToken: res.data.token,
          ipAddress: ipaddress,
        })
      );
      navigate("/dashboard");
    } else {
      toast.error(`Invalid User id or Address`);
      setspinnerload(false);
    }
    setspinnerload(false);
  };

  useEffect(() => {
    walletConnected();
  }, []);

  return (
    <div>
      <div className="container-fluid loginpage vh-100">
        <div className="container h-100">
          <div className="row h-100 py-5 py-lg-0">
            <div className="col-lg-5 col-md-7 col-sm-12 col-12 h-100 mx-auto d-flex align-items-center justify-content-center flex-column">
              <div className="card border-theme1 w-100">
                <div className="card-body">
                  <div className="row mx-0 align-items-center justify-content-center gy-3">
                    <div className="col-12">
                      <div className="logoimage mx-auto" style={{ width: "90px", height: "90px" }}>
                        <img
                          className="w-100 h-100"
                          src="./assets/image/logo/logo.png"
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <div className="loginheading">
                        Access Your Personal Account
                      </div>
                      <div
                        className="subtext"
                        style={{
                          color:
                            connected === "Wallet is Connected"
                              ? "green"
                              : "red",
                        }}
                      >
                        {connected}
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      {/* <Link to="/DashBoard"> */}{" "}
                      <button
                        className="btew btn btn-theme1 btnlogin"
                        disabled
                       // onClick={() => onSubmitHandler()}
                      >
                        Authorized Login
                      </button>
                      {/* </Link> */}
                    </div>
                    <div className="col-12">
                      <div className="remembertext text-center">
                        Remember to authorize with the correct address.View an
                        Account
                      </div>
                    </div>
                    <div className="col-12">
                      <input style={{ height: "47px" }} type="text" placeholder="Please Enter ID or Address " className="control-form w-100 border-0 rounded-3 ps-2 fs-6" value={LoginId} onChange={(e) => setLoginId(e.target.value)}
                        readOnly
                      />
                    </div>
                    <div className="col-12">
                      <div className="remembertext text-center">
                        Enter the account id or  Address
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="remembertext2 text-center">
                        Don’t have an account?{" "}
                        <Link className="text-theme1" to="/Register"> Register</Link>
                      </div>
                      <div className="remembertext2 text-center">
                        Copyright © 2024. All Rights Reserved.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
